#usr {
	height: 38px;
	border-radius: 5px;
	width: 300px;
	border: none;
	padding-left: 6px;
	background: #EFEFEF;
}

.searchInput {
	display: flex;
	align-items: center;
	justify-content: center;

	input {
		width: 100%;
		height: 58px;
		border: 1px solid #CACCFA;
		padding-left: 10px;
		font-size: 18px;
		font-weight: 500;
		border-radius: 4px 0px 0px 4px;
	}

	button {
		height: 58px;
		width: 100px;
		border: 1px solid #CACCFA;
		border-radius: 0px 4px 4px 0px;
		border-left: none;
		background: #CACCFA;

		img {
			width: 24px;
		}
	}
}

.more {
	background: $primary;
	padding: 6px 14px;
	border-radius: $border-radius-base;
	display: inline-block;
	margin-top: 12px;
	font-size: 12px;
	font-weight: 600;
	color: #fff;
	text-transform: uppercase;

	&:hover {
		background-color: $primary;
		color: #fff;
	}

	.more-text {
		color: #fff;
	}
}
.paymentContent{
 display: flex;
 justify-content: center;
 margin-top: 100px;
}
.paymentCont{
 border: 1px #c0c0c0 solid;
 width: 470px;
 height: 550px;
 border-radius: 6px;
 display: flex;
 align-items: center;
 flex-direction: column;
 justify-content: center;
 padding: 4px;
 .title{
  margin-top: 30px;
  font-size: 18px;
  font-weight: bold;
 }
 input{
  margin: 20px 0px;
  width: 380px;
  border-radius: 4px;
  height: 48px;
  padding-left: 8px;
  font-size: 14px;
  border: 1px solid #c0c0c0;
 }
 input:focus{
  outline: 2px solid #007bff;
  border: none;
 }
 .form-submit{
  margin-top: 30px;
 }
}


// banner-wraper


.saveyCont {
  margin-top: 200px;
  span {
    overflow: hidden;
  }

  .container {
    width: 700px;
    margin-bottom: 40px;
    border-radius: 10px;
  }

  .contact-wraper {
    border-radius: 10px !important;
    overflow: hidden;
  }

  @media screen and (max-width: 594px) {
    margin-top:0px;
    .changeQuestionBtn {
      margin-bottom: 20px;

      button {
        font-size: 14px !important;
      }

    }
  }

  @media screen and (max-width: 594px) {
    .container {
      width: 100vw;
      display: flex;
      justify-content: center;
      padding-top: 200px;
    }
  }

  @media screen and (max-width: 370px) {
    .contact-wraper {
      padding-left: 5px;
    }

    .questionCont ul li {
      position: relative;
    }
  }

  .questionCont ul {
    margin-top: 15px;
    margin-bottom: 30px;
    overflow: hidden;

  }

  .questionCont ul li {
    line-height: 27px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 5px;
    padding: 1px;
    border-radius: 4px;
    max-width: 620px;
  }

  .questionCont ul li:hover {
    padding: 0px;
    border: 1px dashed black;
  }

  .questionCont ul li span {
    margin-top: 6px;
  }

  .checkBox {
    margin-right: 7px;
    margin-left: 4px;
  }

  .checkBox input {
    cursor: pointer;
    font-size: 30px;
  }

  .changeQuestionBtn {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }

  .positionBtn {
    background-color: #fff;
    color: $primary;
    padding: 12px 15px;
    font-weight: 600;
    font-size: 14px;
    min-width: 40px;
    line-height: 1;
    display: block;
    border-radius: $border-radius-base  !important;
    border: 2px solid rgba($primary, 0.2);
    cursor: pointer;
    overflow: hidden;
    z-index: 10;
  }

  .positionBtn:hover {
    border-color: transparent;
    background: rgba($primary, 0.9);
    color: #fff;
  }

  .rowCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  .row {
    width: auto;
    height: fit-content(5em) !important;

    div::-webkit-scrollbar {
      display: none;
    }
  }

  .swipeCont {
    div::-webkit-scrollbar {
      display: none;
    }
  }

  .react-swipeable-view-container {}

  .radioBtn input {
    visibility: hidden;
  }

  .radioBtn span {
    display: inline-block;
    padding: 0px;
    height: 19px;
    width: 19px;
    background: none;
    border: .8px solid #333e48;
    border-radius: 50%;
  }

  .checkBtn {
    cursor: pointer;
  }

  .radioBtn input:checked+span {
    background: url('../images/check.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 19px 19px;
    border: none;
  }

  .CheckBoxBtn input {
    height: 19px;
    width: 19px;
    margin-top: 7px;
  }

  .thnk-par {
    margin: 40px 0px;

    p {
      font-size: 13.5px;
      text-align: left;
    }
  }

  .textAreaCont {
    max-width: 620px;

    textarea {
      border: 2px solid #bfbfbf;
      border-radius: 6px;
      padding-left: 6px;
      padding-top: 5px;
      font-size: 14px;
    }
  }

  .onBehalfCont {
    padding-top: 10px;

    h4 {
      text-align: start;
    }
  }
}

.modelCont {
  .backGround {
    background: black;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    top: 0px;
    left: 0px;
    opacity: 0.7;
  }

  .mainPart {
    background: white;
    width: 5;
    padding: 30px;
    border-radius: 10px;
    width: 1000px;
    max-height: 93vh;

    @include respond('phone') {
      padding: 30px 10px;
    }
  }

  .mainPartCont {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    z-index: 9999999;
  }

  .mainContent {
    margin-top: 30px;

    p {
      font-size: 13.5px;
    }
  }

  .changeQuestionBtn {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-top: 40px;
  }

  .positionBtn {
    background-color: #565ACF;
    // color: $primary;
    color: #fff;
    padding: 12px 15px;
    font-weight: 700;
    font-size: 17px;
    min-width: 40px;
    line-height: 1;
    display: block;
    border-radius: $border-radius-base  !important;
    border: 2px solid rgba($primary, 0.2);
    cursor: pointer;
    overflow: hidden;
    z-index: 10;

    @include respond('phone') {
      padding: 10px 15px;
      font-weight: 600;
      font-size: 14px;
    }
  }

  .positionBtn:hover {
    border-color: transparent;
    background: rgba($primary, 0.9);
    color: #fff;
  }
}

.swipeCont {
  #swipeContout {
    .react-swipeable-view-container {
      height: min-content;

      div[aria-hidden=true] {
        height: 0px !important;
        height: fit-content;
      }
    }
  }
}

.saveyContainer {
  @include respond('phone') {
    display: flex;
    justify-content: center;
    width: 100vw;
    // padding-left: 0px;
  }
}

.phonePadding {
  @include respond('phone') {
    padding-left: 0px !important;
  }
}

.notification{
 position: fixed;
 right: 20px;
 top: 34px;
 z-index: 999999;
 width: 310px;
 .content{
  display: flex;
  align-items: flex-start;
 }
}
.closeBtnn{
 font-size: 30px;
 position: relative;
 bottom: 22px;
 left: 7px;
 cursor: pointer;
}

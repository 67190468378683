.submitComment{
 background: #565ACF;
 color: white;
 padding: 15px 25px;
 border: none;
 border-radius: 8px;
 font-size: 20px;
 font-weight: 20px;
}
.loading{
 width: 170px;
  background: #565ACF;
 color: white;
 padding: 14px 0px;
 border-radius: 8px;
 border: none;
}
/* ==================
	8.20 Table
=================== */
.table-responsive-sm {
    min-width: 48rem;
}

.table {
    margin-bottom: 0px;
    font-weight: 400;

    tr {

        td,
        th {
            padding: 15px;
        }
    }
}

.table {
    tr {
        th {
            color: $headings-color;
            font-weight: 500;
        }
    }
}

.table-striped {
    thead {
        tr {
            th {
                padding-bottom: 15px !important;
            }
        }
    }

    tbody tr:nth-of-type(#{$table-striped-order}) {
        background-color: $body-bg;
    }

    tfoot {
        border-top: 2px solid $border-color;

        tr {
            th {
                padding-top: 10px;
            }
        }
    }
}
.tableName {
    border: 2px solid rgba(86, 90, 207, 0.3);
    border-radius: 3px;

    .titles {
        flex: 1;
        min-width: 300px;
        padding-left: 10px;
        min-height: 35px;
        display: flex;
        padding-top: 7px;

        @include respond('phone-land') {
            min-width: 150px;
        }

    }

    .dataParty {
        flex: 4;
        border-left: 2px solid rgba(86, 90, 207, 0.3);
        padding-left: 20px;
        display: flex;
        align-items: center;
    }

    .tableRow {
        display: flex;
        border-bottom: 2px solid rgba(86, 90, 207, 0.3);
    }

    .tableRow:last-child {
        border-bottom: 0px;
    }
}
/* ==================
	8.23 Work Box
=================== */
.work-area {
	.pt-img1 {
		bottom: 15%;
		left: 5%;
	}

	.pt-img2 {
		top: 50%;
		right: 5%;

		@include respond('tab-port') {
			top: 250px;
			width: 100px;
			height: 100px;
			right: 10px;
		}
	}

	.pt-img3 {
		bottom: 10%;
		right: 20%;

		@include respond('tab-port') {
			bottom: 120px;
			right: 430px;
		}
	}

	.work-bx {
		margin-top: 30px;

		@include respond('tab-port') {
			margin-top: 0;
		}
	}

	.work-bx.active {
		margin-top: 0;
	}
}

.work-bx {
	position: relative;
	box-shadow: 0px 10px 60px 0px rgba(31, 34, 120, 0.1);
	padding: 40px 30px;
	border-radius: 20px;
	background-color: $light;
	@include transitionMedium;
	overflow: hidden;
	z-index: 1;

	&:after {
		content: "";
		height: 0;
		width: 100%;
		background-color: $secondary;
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
		@include transitionMedium;
		transform-origin: top;
		-moz-transform-origin: top;
		-ms-transform-origin: top;
		-webkit-transform-origin: top;
		-o-transform-origin: top;
	}

	.work-num-bx {
		@include transitionMedium;
		z-index: 9;
		font-size: 80px;
		font-weight: 900;
		margin-bottom: 30px;
		line-height: 80px;
		text-shadow: 0 10px 0 #ffe3d3;
		color: transparent;
	}

	.work-content {
		transition: all 0.5s;
		@include transitionMedium;

		p {
			font-size: 14px;
			line-height: 24px;
			font-weight: 500;
			margin-bottom: 30px;
		}
	}

	.btn {
		font-weight: 600;
	}

	&.active,
	&:hover {
		&:after {
			height: 100%;
			top: auto;
			bottom: 0;
			transform-origin: bottom;
			-moz-transform-origin: bottom;
			-ms-transform-origin: bottom;
			-webkit-transform-origin: bottom;
			-o-transform-origin: bottom;
			animation-duration: 0.5s;
		}

		.work-num-bx {
			text-shadow: 0 10px 0 #f38f5e;
			-webkit-text-stroke: 2px #fff;
		}

		.work-content {

			p,
			.title {
				color: $light  !important;
			}
		}

		.btn {
			background-color: #fff;
			border-color: #fff;
			color: $secondary;

			.btn-icon-bx {
				background-color: #fff;
				color: $secondary;
			}
		}
	}

}

.outContainer {
	display: flex;
	padding-top: 20px;
}

.applePhone {
	width: 382px;
	height: 762px;
	position: absolute;
	top: -16px;

	@media screen and (max-width: 375px) {
		width: 342px;
		height: auto;
	}
}

.connentInnerr {
	border-width: 3px;
	background: white;
	padding: 0;
	width: 360px;
	height: 730px;
	border-radius: 30px;
	display: flex;
	align-items: center;
	overflow: hidden;
	position: relative;
	left: 10px;

	@media screen and (max-width: 375px) {
		width: 322px;
		height: 659px !important;
	}

	.slideNumber {
		position: absolute;
		color: black;
		top: 50px;
		width: 370px;
		display: flex;
		justify-content: center;

		@include respond('phone') {
			height: auto;
			width: 360px !important;
			min-height: 730px;
		}

		div {
			background: white;
			width: 35px;
			height: 35px;
			font-size: 15px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 9999999;
		}
	}

	.daytitsTitle {
		position: absolute;
		width: 360px;
		display: flex;
		justify-content: center;
		bottom: 70px;
	}

	.next {
		display: flex;
		justify-content: center;
		padding-left: 2px;
		margin-right: 16px;
		z-index: 99999;
		right: -4px;
		position: absolute;
	}

	.prev {
		z-index: 99999;
		padding-right: 2px;
		left: 15px;
		position: absolute;

		@media screen and (max-width: 375px) {
			left: 15px;


		}
	}

	.nextPrevIcon {
		div {
			width: 40px;
			height: 40px;
			background: white;
			border-radius: 100%;
			cursor: pointer;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			@media screen and (max-width: 375px) {
				width: 30px;
				height: 30px;
			}
		}

		img {
			width: 20px;
			height: 20px;
			position: relative;
			left: 10px;
			bottom: 1px;

			@media screen and (max-width: 375px) {
				width: 15px;
				height: 15px;
				left: 7px;
				top: 1px;
			}
		}

		.prev {
			img {
				position: relative;
				left: 10px;
				bottom: 1px;

				@media screen and (max-width: 375px) {
					left: 15px;
				}
			}

		}

		.next img {
			left: 10px;
			bottom: 1px;

			@media screen and (max-width: 375px) {}
		}

	}

	div::-webkit-scrollbar {
		display: none;
	}
}

.connentInner {
	background: #EDEDF5;
	height: 357px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;

	.inneData {
		z-index: 999;
		font-size: 20px;
		text-align: center;
		width: 330px;
		font-family: "Montserrat", sans-serif;
		color: #fff;
		font-size: 20px;
		font-weight: 700;
	}

}

.seeMoreLinks {}

.seeMoreLinks span {
	text-decoration: underline 2px;
}

@include respond('phone') {
	.workPhone {
		display: flex;
		justify-content: center;
	}
}
.mainBanner {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-around;

  img {
    height: 80vh;
  }

  .content {
    .donateBtn {}

    .expText {
      width: 500px;
      font-size: 15px;
      margin-bottom: 20px;
      margin-top: 20px;
      text-align: justify;
    }

    .title {
      font-size: 42px;
      font-weight: bold;
    }

  }

  .form-submit {
    margin-top: 60px;
  }
}

.btnText {
  font-size: 15px;
}


.contactCard {
  display: flex;
}
/* ==================
	8.11 Form
=================== */
.form-group {
    position: relative;

    label {
        font-size: 14px;
        font-weight: 500;
        color: $body-color;
        margin-bottom: 10px;
        background: transparent;
        border-color: $border-color;
    }
}

.form-control {
    border-radius: 6px;
    border: 1px solid transparent;
    padding: 15px 25px;
    font-size: 14px;
    font-weight: 400;
    color: $body-color;
    transition: all 0.3s ease-in-out;
    background: $body-bg;

    .select {
        padding: 12px 0;
    }

    span {
        margin-top: 0;
    }

    &::-webkit-input-placeholder {
        color: $body-color;
    }

    &:-ms-input-placeholder {
        color: $body-color;
    }

    &::placeholder {
        color: $body-color;
    }

    &:hover {
        box-shadow: none !important;
        border-color: $border-color;
        color: $body-color;
        background: $body-bg;
    }

    &:focus,
    &:active,
    &.active {
        box-shadow: none !important;
        border-color: $primary;
        color: $body-color;
        background: $body-bg;
        border-radius: 6px;
    }
}

input:-internal-autofill-selected {
    background: lighten($body-bg, 2%) !important;
    background-image: none !important;
    color: -internal-light-dark-color(black, white) !important;
}

.input-group-text {
    padding: 5px 15px;
    background: $body-bg;
    margin-bottom: 0px !important;
    color: $body-color;
    border-color: transparent;
}

.input-group-append {
    .input-group-text {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.input-group-prepend {
    .input-group-text {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
}


/*Styling Selectbox*/

//
.toggle {
    cursor: pointer;
    display: block;
}

.toggle-switch {
    display: inline-block;
    background: darken($body-bg, 3%);
    border-radius: 16px;
    width: 45px;
    height: 20px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;

    &:before,
    &:after {
        content: "";
    }

    &:before {
        display: block;
        background: linear-gradient(to bottom, $white 0%, #eee 100%);
        border-radius: 50%;
        width: 17px;
        height: 17px;
        position: absolute;
        top: 1px;
        left: 3px;
        transition: left 0.25s;
    }

    .toggle:hover &:before {
        background: linear-gradient(to bottom, $white 0%, $white 100%);
    }

    .toggle-checkbox:checked+& {
        background: $primary;

        &:before {
            left: 26px;
        }
    }
}

.toggle-checkbox {
    position: absolute;
    visibility: hidden;
}

.toggle-label {
    margin-left: 15px;
    position: relative;
    top: 2px;
    font-size: 16px;
    font-weight: 400;
}

// Upload btn
.file-upload-wrapper {
    position: relative;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    border: 1px solid $border-color;
    border-radius: 5px;
    color: $text-muted;

    &:after {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        background: $body-bg;
        padding: 4px 15px;
        display: block;
        width: calc(100% - 40px);
        pointer-events: none;
        z-index: 20;
        height: calc(1.5em + 1rem + 2px);
        line-height: 50px - 20px;
        border-radius: 5px 10px 10px 5px;
        font-weight: 400;
        overflow: hidden;
    }

    &:before {
        content: 'Upload';
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        height: calc(1.5em + 1rem + 2px);
        ;
        background: $primary;
        color: $white;
        font-weight: 400;
        z-index: 25;
        font-size: 14px;
        line-height: 40px;
        padding: 0 15px;
        text-transform: capitalize;
        pointer-events: none;
        border-radius: 0 5px 5px 0;
    }

    &:hover {
        &:before {
            background: darken($primary, 10%);
        }
    }

    input {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
        height: calc(1.5em + 1rem + 2px);
        margin: 0;
        padding: 0;
        display: block;
        cursor: pointer;
        width: 100%;
    }
}

// Date picker
#ui-datepicker-div {
    display: none;
    background: darken($body-bg, 2%);
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
    margin-top: 0.25rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.ui-datepicker-calendar {
    thead th {
        padding: 0.25rem 0;
        text-align: center;
        font-size: 0.75rem;
        font-weight: 400;
        color: $body-color;
    }

    tbody td {
        width: 2.5rem;
        text-align: center;
        padding: 0;

        a {
            display: block;
            border-radius: 0.25rem;
            line-height: 2rem;
            transition: 0.3s all;
            color: $body-color;
            font-size: 0.875rem;
            text-decoration: none;

            &:hover {
                background-color: $primary;
                color: white;
            }

            &.ui-state-active {
                background-color: $primary;
                color: white;
            }
        }
    }
}

.ui-datepicker-header a {
    &.ui-corner-all {
        cursor: pointer;
        position: absolute;
        top: 0;
        width: 2rem;
        height: 2rem;
        margin: 0.5rem;
        border-radius: 0.25rem;
        transition: 0.3s all;

        &:hover {
            background-color: lighten($primary, 30%);
            color: $white;
        }
    }

    &.ui-datepicker-prev {
        left: 0;
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
        background-repeat: no-repeat;
        background-size: 0.5rem;
        background-position: 50%;
        transform: rotate(180deg);
    }

    &.ui-datepicker-next {
        right: 0;
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: 50%;
    }

    >span {
        display: none;
    }
}

.ui-datepicker-title {
    text-align: center;
    line-height: 2rem;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
    color: $body-color;
    font-weight: 400;
    font-size: 0.75rem;
}

// Validation Error
label.error {
    color: $danger;
    position: absolute;
    bottom: 0;
    margin-bottom: -22px;
    font-size: 12px;
    font-weight: 400;
}

// Nice Select
.bootstrap-select {
    width: 100% !important;

    .dropdown-toggle {
        width: 100%;
        padding: 10px;
        font-family: $font-family-base;
        text-transform: none;
        background: #fff;
        color: $body-color;
        font-size: 14px;
        outline: none !important;

        &:focus {
            outline: none !important;
        }

        &:after {
            content: none;
        }
    }

    &.show.btn-group .dropdown-menu.inner {
        display: block;
    }

    .dropdown-menu {
        border: 0;
        border-radius: 0;
        margin-top: -1px;
        padding: 10px 0;
        font-size: 14px;
        box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);

        a {
            padding: 5px 25px;
            display: block;

            &:hover {
                background: $light;
            }
        }
    }

}

.bootstrap-select .list {
    background: $dark;
}

// Nice Select
.nice-select {
    .list {
        width: 100%;
        border: 0;
        margin: 1px 0 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 8px 0;

        li {
            font-weight: 500;
        }

        .option:hover,
        .option.selected {
            background-color: $primary;
            color: #fff;
        }
    }

    &:after {
        right: 22px;
    }
}